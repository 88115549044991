$breakpoints: (
  "sm-phone": 375px,
  "phone": 599px,
  "tablet-portrait": 768px,
  "tablet-landscape": 1024px,
  "desktop": 1280px,
  "big-desktop": 1440px,
  "xl-desktop": 1680px,
  "xxl-desktop": 1920px,
) !default;

@mixin screen($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media only screen and (max-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  } @else {
    @error "Breakpoint `#{$breakpoint}` can not be found." + "Available breakpoints are : #{map-keys($breakpoints)}";
  }
}
