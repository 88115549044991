.app-container {
  max-width: 1200px;
  margin: 0 auto;
}

.app-container-sm {
  max-width: calc(1128px + 30px * 2);
  padding: 0 30px;
  width: 100%;
  margin: 0 auto;

  @include screen('phone') {
    padding: 0 16px;
  }
}
