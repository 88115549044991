@import 'color-data-base';
@import 'breakpoints';
@import 'page';
@import 'material';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

html,
body {
  height: 100%;
}

body {
  font-family: Roboto, sans-serif;
  font-weight: 300;
  line-height: 1.5;
  color: var(--text-default-primary);
  background-color: var(--default-black);
}

a,
button {
  background: none;
  border: none;
  text-decoration: none;
  -webkit-text-decoration-skip: objects;

  &:not(:disabled, .disabled) {
    cursor: pointer;
  }
}

button::-moz-focus-inner {
  border: 0;
}

a:active,
a:hover,
button:active,
button:hover {
  outline-width: 0;
}

img {
  display: block;
  margin: 0;
  border: 0;
}

.no-scroll {
  overflow: hidden;
}
