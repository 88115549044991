:root {
  // Brand Graphic-1 colors
  --color-brand-graphic1-0: #fff;
  --color-brand-graphic1-25: #fbf8ff;
  --color-brand-graphic1-50: #f4ebfe;
  --color-brand-graphic1-100: #eddefd;
  --color-brand-graphic1-150: #e6d1fc;
  --color-brand-graphic1-200: #dfc4fc;
  --color-brand-graphic1-250: #d8b6fb;
  --color-brand-graphic1-300: #d1a9fa;
  --color-brand-graphic1-350: #ca9cfa;
  --color-brand-graphic1-400: #c28ff9;
  --color-brand-graphic1-450: #bb82f8;
  --color-brand-graphic1-500: #a65af6;
  --color-brand-graphic1-550: #7e44bb;
  --color-brand-graphic1-600: #713da7;
  --color-brand-graphic1-650: #643694;
  --color-brand-graphic1-700: #562f80;
  --color-brand-graphic1-750: #49286c;
  --color-brand-graphic1-800: #3c2059;
  --color-brand-graphic1-850: #2f1945;
  --color-brand-graphic1-900: #211231;
  --color-brand-graphic1-950: #140b1e;
  --color-brand-graphic1-1000: #000;

  // Brand Graphic-2 colors
  --color-brand-graphic2-0: #fff;
  --color-brand-graphic2-25: #fff6fa;
  --color-brand-graphic2-50: #ffe3f0;
  --color-brand-graphic2-100: #ffd1e7;
  --color-brand-graphic2-150: #ffbedd;
  --color-brand-graphic2-200: #ffabd3;
  --color-brand-graphic2-250: #ff99ca;
  --color-brand-graphic2-300: #ff86c0;
  --color-brand-graphic2-350: #ff74b6;
  --color-brand-graphic2-400: #ff61ad;
  --color-brand-graphic2-450: #ff4fa3;
  --color-brand-graphic2-500: #ff1786;
  --color-brand-graphic2-550: #c21166;
  --color-brand-graphic2-600: #ad105b;
  --color-brand-graphic2-650: #990e50;
  --color-brand-graphic2-700: #850c46;
  --color-brand-graphic2-750: #700a3b;
  --color-brand-graphic2-800: #5c0830;
  --color-brand-graphic2-850: #470626;
  --color-brand-graphic2-900: #33051b;
  --color-brand-graphic2-950: #1f0310;
  --color-brand-graphic2-1000: #000;

  // Brand Graphic-3 colors
  --color-brand-graphic3-0: #fff;
  --color-brand-graphic3-25: #f5fcff;
  --color-brand-graphic3-50: #e1f5fe;
  --color-brand-graphic3-100: #cdeefd;
  --color-brand-graphic3-150: #b8e7fc;
  --color-brand-graphic3-200: #a4e0fb;
  --color-brand-graphic3-250: #90d9fa;
  --color-brand-graphic3-300: #7cd2f9;
  --color-brand-graphic3-350: #68cbf8;
  --color-brand-graphic3-400: #54c5f8;
  --color-brand-graphic3-450: #3fbef7;
  --color-brand-graphic3-500: #03a9f4;
  --color-brand-graphic3-550: #0280b9;
  --color-brand-graphic3-600: #0273a6;
  --color-brand-graphic3-650: #026592;
  --color-brand-graphic3-700: #02587f;
  --color-brand-graphic3-750: #014a6b;
  --color-brand-graphic3-800: #013d58;
  --color-brand-graphic3-850: #012f44;
  --color-brand-graphic3-900: #012f44;
  --color-brand-graphic3-950: #012231;
  --color-brand-graphic3-1000: #000;

  // Brand Graphic-4 colors
  --color-brand-graphic4-0: #fff;
  --color-brand-graphic4-25: #f5fcfd;
  --color-brand-graphic4-50: #e0f7fa;
  --color-brand-graphic4-100: #ccf2f6;
  --color-brand-graphic4-150: #b8ecf3;
  --color-brand-graphic4-200: #a3e7ef;
  --color-brand-graphic4-250: #8fe1ec;
  --color-brand-graphic4-300: #7adce9;
  --color-brand-graphic4-350: #66d7e5;
  --color-brand-graphic4-400: #52d1e2;
  --color-brand-graphic4-450: #3dccde;
  --color-brand-graphic4-500: #00bcd4;
  --color-brand-graphic4-550: #008fa1;
  --color-brand-graphic4-600: #008090;
  --color-brand-graphic4-650: #00717f;
  --color-brand-graphic4-700: #00626e;
  --color-brand-graphic4-750: #00535d;
  --color-brand-graphic4-800: #00444c;
  --color-brand-graphic4-850: #00353b;
  --color-brand-graphic4-900: #00262a;
  --color-brand-graphic4-950: #001719;
  --color-brand-graphic4-1000: #000;

  // Brand Main colors
  --color-brand-main-0: #fff;
  --color-brand-main-25: #f9f9fe;
  --color-brand-main-50: #ececfd;
  --color-brand-main-100: #dfdffc;
  --color-brand-main-150: #d2d2fb;
  --color-brand-main-200: #c5c5fa;
  --color-brand-main-250: #b8b8f8;
  --color-brand-main-300: #ababf7;
  --color-brand-main-350: #9e9ef6;
  --color-brand-main-400: #9191f5;
  --color-brand-main-450: #8484f4;
  --color-brand-main-500: #5d5df0;
  --color-brand-main-550: #4747b6;
  --color-brand-main-600: #3f3fa3;
  --color-brand-main-650: #383890;
  --color-brand-main-700: #30307d;
  --color-brand-main-750: #29296a;
  --color-brand-main-800: #222256;
  --color-brand-main-850: #1a1a43;
  --color-brand-main-900: #131330;
  --color-brand-main-950: #0b0b1d;
  --color-brand-main-1000: #000;

  // Brand Neutral colors
  --color-brand-neutral-0: #fff;
  --color-brand-neutral-25: #f9f9fa;
  --color-brand-neutral-50: #ededf1;
  --color-brand-neutral-100: #e1e1e7;
  --color-brand-neutral-150: #d6d6de;
  --color-brand-neutral-200: #cacad4;
  --color-brand-neutral-250: #bebeca;
  --color-brand-neutral-300: #b2b2c1;
  --color-brand-neutral-350: #a6a6b7;
  --color-brand-neutral-400: #9a9aae;
  --color-brand-neutral-450: #8e8ea4;
  --color-brand-neutral-500: #6a6a87;
  --color-brand-neutral-550: #515167;
  --color-brand-neutral-600: #48485c;
  --color-brand-neutral-650: #404051;
  --color-brand-neutral-700: #373746;
  --color-brand-neutral-750: #2f2f3c;
  --color-brand-neutral-800: #272731;
  --color-brand-neutral-850: #1e1e26;
  --color-brand-neutral-900: #16161b;
  --color-brand-neutral-950: #0d0d10;
  --color-brand-neutral-1000: #000;

  // Brand Secondary colors
  --color-brand-secondary-0: #fff;
  --color-brand-secondary-25: #fefdf9;
  --color-brand-secondary-50: #fdfaec;
  --color-brand-secondary-100: #fcf7df;
  --color-brand-secondary-150: #fbf3d2;
  --color-brand-secondary-200: #faf0c5;
  --color-brand-secondary-250: #f8ecb8;
  --color-brand-secondary-300: #f7e9ab;
  --color-brand-secondary-350: #f6e69e;
  --color-brand-secondary-400: #f5e291;
  --color-brand-secondary-450: #f4df84;
  --color-brand-secondary-500: #f0d55d;
  --color-brand-secondary-550: #b6a247;
  --color-brand-secondary-600: #a3913f;
  --color-brand-secondary-650: #908038;
  --color-brand-secondary-700: #7d6f30;
  --color-brand-secondary-750: #6a5e29;
  --color-brand-secondary-800: #564d22;
  --color-brand-secondary-850: #433c1a;
  --color-brand-secondary-900: #302b13;
  --color-brand-secondary-950: #1d1a0b;
  --color-brand-secondary-1000: #000;

  // Global Amber colors
  --color-global-amber-0: #fff;
  --color-global-amber-25: #fffdf5;
  --color-global-amber-50: #fff8e1;
  --color-global-amber-100: #fff3cd;
  --color-global-amber-150: #ffeeb9;
  --color-global-amber-200: #ffe9a6;
  --color-global-amber-250: #ffe492;
  --color-global-amber-300: #ffdf7e;
  --color-global-amber-350: #ffda6a;
  --color-global-amber-400: #ffd556;
  --color-global-amber-450: #ffd043;
  --color-global-amber-500: #ffc107;
  --color-global-amber-550: #c29305;
  --color-global-amber-600: #ad8305;
  --color-global-amber-650: #997404;
  --color-global-amber-700: #856404;
  --color-global-amber-750: #705503;
  --color-global-amber-800: #5c4603;
  --color-global-amber-850: #473602;
  --color-global-amber-900: #332701;
  --color-global-amber-950: #1f1701;
  --color-global-amber-1000: #000;

  // Global Blue colors
  --color-global-blue-0: #fff;
  --color-global-blue-25: #f6fbff;
  --color-global-blue-50: #e4f2fe;
  --color-global-blue-100: #d3eafd;
  --color-global-blue-150: #c1e2fc;
  --color-global-blue-200: #afd9fb;
  --color-global-blue-250: #9dd1fa;
  --color-global-blue-300: #8cc8f9;
  --color-global-blue-350: #7ac0f8;
  --color-global-blue-400: #68b8f7;
  --color-global-blue-450: #56aff6;
  --color-global-blue-500: #2196f3;
  --color-global-blue-550: #1972b9;
  --color-global-blue-600: #1666a5;
  --color-global-blue-650: #145a92;
  --color-global-blue-700: #114e7e;
  --color-global-blue-750: #0f426b;
  --color-global-blue-800: #0c3658;
  --color-global-blue-850: #092a44;
  --color-global-blue-900: #071e31;
  --color-global-blue-950: #04121d;
  --color-global-blue-1000: #000;

  // Global Blue Grey colors
  --color-global-blue-grey-0: #fff;
  --color-global-blue-grey-25: #f9fafa;
  --color-global-blue-grey-50: #eceff1;
  --color-global-blue-grey-100: #dfe5e8;
  --color-global-blue-grey-150: #d2dbde;
  --color-global-blue-grey-200: #c6d0d5;
  --color-global-blue-grey-250: #b9c6cc;
  --color-global-blue-grey-300: #acbbc3;
  --color-global-blue-grey-350: #a0b1b9;
  --color-global-blue-grey-400: #93a7b0;
  --color-global-blue-grey-450: #869ca7;
  --color-global-blue-grey-500: #607d8b;
  --color-global-blue-grey-550: #495f6a;
  --color-global-blue-grey-600: #41555f;
  --color-global-blue-grey-650: #3a4b53;
  --color-global-blue-grey-700: #324148;
  --color-global-blue-grey-750: #2a373d;
  --color-global-blue-grey-800: #232d32;
  --color-global-blue-grey-850: #1b2327;
  --color-global-blue-grey-900: #13191c;
  --color-global-blue-grey-950: #0c0f11;
  --color-global-blue-grey-1000: #000;

  // Global Brown colors
  --color-global-brown-0: #fff;
  --color-global-brown-25: #faf8f8;
  --color-global-brown-50: #efebe9;
  --color-global-brown-100: #e4ddda;
  --color-global-brown-150: #d9cfcc;
  --color-global-brown-200: #cfc2bd;
  --color-global-brown-250: #c4b4ae;
  --color-global-brown-300: #b9a7a0;
  --color-global-brown-350: #af9991;
  --color-global-brown-400: #a48b83;
  --color-global-brown-450: #997e74;
  --color-global-brown-500: #795548;
  --color-global-brown-550: #5c4137;
  --color-global-brown-600: #523a31;
  --color-global-brown-650: #49332b;
  --color-global-brown-700: #3f2c25;
  --color-global-brown-750: #352520;
  --color-global-brown-800: #2c1f1a;
  --color-global-brown-850: #221814;
  --color-global-brown-900: #18110e;
  --color-global-brown-950: #0f0a09;
  --color-global-brown-1000: #000;

  // Global Deep Orange colors
  --color-global-deep-orange-0: #fff;
  --color-global-deep-orange-25: #fff8f6;
  --color-global-deep-orange-50: #ffebe4;
  --color-global-deep-orange-100: #ffddd3;
  --color-global-deep-orange-150: #ffd0c1;
  --color-global-deep-orange-200: #ffc2af;
  --color-global-deep-orange-250: #ffb59e;
  --color-global-deep-orange-300: #ffa88c;
  --color-global-deep-orange-350: #ff9a7a;
  --color-global-deep-orange-400: #ff8d69;
  --color-global-deep-orange-450: #ff7f57;
  --color-global-deep-orange-500: #ff5722;
  --color-global-deep-orange-550: #c2421a;
  --color-global-deep-orange-600: #ad3b17;
  --color-global-deep-orange-650: #993414;
  --color-global-deep-orange-700: #852d12;
  --color-global-deep-orange-750: #70260f;
  --color-global-deep-orange-800: #5c1f0c;
  --color-global-deep-orange-850: #47180a;
  --color-global-deep-orange-900: #331107;
  --color-global-deep-orange-950: #1f0a04;
  --color-global-deep-orange-1000: #000;

  // Global Green colors
  --color-global-green-0: #fff;
  --color-global-green-25: #f8fcf8;
  --color-global-green-50: #eaf5ea;
  --color-global-green-100: #dbefdc;
  --color-global-green-150: #cde9ce;
  --color-global-green-200: #bfe2c0;
  --color-global-green-250: #b0dcb2;
  --color-global-green-300: #a2d5a4;
  --color-global-green-350: #94cf96;
  --color-global-green-400: #85c988;
  --color-global-green-450: #77c27a;
  --color-global-green-500: #4caf50;
  --color-global-green-550: #3a853d;
  --color-global-green-600: #347736;
  --color-global-green-650: #2e6930;
  --color-global-green-700: #285b2a;
  --color-global-green-750: #214d23;
  --color-global-green-800: #1b3f1d;
  --color-global-green-850: #153116;
  --color-global-green-900: #0f2310;
  --color-global-green-950: #09150a;
  --color-global-green-1000: #000;

  // Global Grey colors
  --color-global-grey-0: #fff;
  --color-global-grey-25: #fbfbfb;
  --color-global-grey-50: #f3f3f3;
  --color-global-grey-100: #ececec;
  --color-global-grey-150: #e4e4e4;
  --color-global-grey-200: #dcdcdc;
  --color-global-grey-250: #d4d4d4;
  --color-global-grey-300: #cdcdcd;
  --color-global-grey-350: #c5c5c5;
  --color-global-grey-400: #bdbdbd;
  --color-global-grey-450: #b5b5b5;
  --color-global-grey-500: #9e9e9e;
  --color-global-grey-550: #787878;
  --color-global-grey-600: #6b6b6b;
  --color-global-grey-650: #5f5f5f;
  --color-global-grey-700: #525252;
  --color-global-grey-750: #464646;
  --color-global-grey-800: #393939;
  --color-global-grey-850: #2c2c2c;
  --color-global-grey-900: #202020;
  --color-global-grey-950: #131313;
  --color-global-grey-1000: #000;

  // Global Indigo colors
  --color-global-indigo-0: #fff;
  --color-global-indigo-25: #f7f8fc;
  --color-global-indigo-50: #e8eaf6;
  --color-global-indigo-100: #d9dcf0;
  --color-global-indigo-150: #c9ceea;
  --color-global-indigo-200: #bac0e4;
  --color-global-indigo-250: #aab2de;
  --color-global-indigo-300: #9ba5d9;
  --color-global-indigo-350: #8c97d3;
  --color-global-indigo-400: #7c89cd;
  --color-global-indigo-450: #6d7bc7;
  --color-global-indigo-500: #3f51b5;
  --color-global-indigo-550: #303e8a;
  --color-global-indigo-600: #2b377b;
  --color-global-indigo-650: #26316d;
  --color-global-indigo-700: #212a5e;
  --color-global-indigo-750: #1c2450;
  --color-global-indigo-800: #171d41;
  --color-global-indigo-850: #121733;
  --color-global-indigo-900: #0d1024;
  --color-global-indigo-950: #080a16;
  --color-global-indigo-1000: #000;

  // Global Light Green colors
  --color-global-light-green-0: #fff;
  --color-global-light-green-25: #fafdf8;
  --color-global-light-green-50: #f1f8e9;
  --color-global-light-green-100: #e8f3db;
  --color-global-light-green-150: #deeecc;
  --color-global-light-green-200: #d5e9be;
  --color-global-light-green-250: #cce5af;
  --color-global-light-green-300: #c3e0a1;
  --color-global-light-green-350: #b9db92;
  --color-global-light-green-400: #b0d684;
  --color-global-light-green-450: #a7d175;
  --color-global-light-green-500: #8bc34a;
  --color-global-light-green-550: #6a9438;
  --color-global-light-green-600: #5f8532;
  --color-global-light-green-650: #53752c;
  --color-global-light-green-700: #486526;
  --color-global-light-green-750: #3d5621;
  --color-global-light-green-800: #32461b;
  --color-global-light-green-850: #273715;
  --color-global-light-green-900: #1c270f;
  --color-global-light-green-950: #111709;
  --color-global-light-green-1000: #000;

  // Global Lime colors
  --color-global-lime-0: #fff;
  --color-global-lime-25: #fdfef7;
  --color-global-lime-50: #f9fbe7;
  --color-global-lime-100: #f5f8d7;
  --color-global-lime-150: #f1f5c8;
  --color-global-lime-200: #edf2b8;
  --color-global-lime-250: #e9f0a8;
  --color-global-lime-300: #e5ed98;
  --color-global-lime-350: #e1ea88;
  --color-global-lime-400: #dde778;
  --color-global-lime-450: #d9e469;
  --color-global-lime-500: #cddc39;
  --color-global-lime-550: #9ca72b;
  --color-global-lime-600: #8b9627;
  --color-global-lime-650: #7b8422;
  --color-global-lime-700: #6b721e;
  --color-global-lime-750: #5a6119;
  --color-global-lime-800: #4a4f15;
  --color-global-lime-850: #393e10;
  --color-global-lime-900: #292c0b;
  --color-global-lime-950: #191a07;
  --color-global-lime-1000: #000;

  // Global Pinc colors
  --color-global-pink-0: #fff;
  --color-global-pink-25: #fef6f9;
  --color-global-pink-50: #fce4ec;
  --color-global-pink-100: #fbd2e0;
  --color-global-pink-150: #f9c0d3;
  --color-global-pink-200: #f7aec7;
  --color-global-pink-250: #f59cba;
  --color-global-pink-300: #f48aae;
  --color-global-pink-350: #f278a1;
  --color-global-pink-400: #f06695;
  --color-global-pink-450: #ee5488;
  --color-global-pink-500: #e91e63;
  --color-global-pink-550: #b1174b;
  --color-global-pink-600: #9e1443;
  --color-global-pink-650: #8c123b;
  --color-global-pink-700: #791033;
  --color-global-pink-750: #670d2c;
  --color-global-pink-800: #540b24;
  --color-global-pink-850: #41081c;
  --color-global-pink-900: #2f0614;
  --color-global-pink-950: #1c040c;
  --color-global-pink-1000: #000;

  // Global Purple colors
  --color-global-purple-0: #fff;
  --color-global-purple-25: #fbf6fc;
  --color-global-purple-50: #f3e5f6;
  --color-global-purple-100: #ebd4ef;
  --color-global-purple-150: #e3c2e9;
  --color-global-purple-200: #dbb1e3;
  --color-global-purple-250: #d3a0dc;
  --color-global-purple-300: #cc8fd6;
  --color-global-purple-350: #c47dd0;
  --color-global-purple-400: #bc6cc9;
  --color-global-purple-450: #b45bc3;
  --color-global-purple-500: #9c27b0;
  --color-global-purple-550: #771e86;
  --color-global-purple-600: #6a1b78;
  --color-global-purple-650: #5e176a;
  --color-global-purple-700: #51145c;
  --color-global-purple-750: #45114e;
  --color-global-purple-800: #380e3f;
  --color-global-purple-850: #2c0b31;
  --color-global-purple-900: #1f0823;
  --color-global-purple-950: #130515;
  --color-global-purple-1000: #000;

  // Global Red colors
  --color-global-red-0: #fff;
  --color-global-red-25: #fff7f7;
  --color-global-red-50: #fee8e7;
  --color-global-red-100: #fdd9d7;
  --color-global-red-150: #fccac7;
  --color-global-red-200: #fbbbb7;
  --color-global-red-250: #faaca6;
  --color-global-red-300: #f99d96;
  --color-global-red-350: #f88e86;
  --color-global-red-400: #f87f76;
  --color-global-red-450: #f77066;
  --color-global-red-500: #f44336;
  --color-global-red-550: #b93329;
  --color-global-red-600: #a62e25;
  --color-global-red-650: #922820;
  --color-global-red-700: #7f231c;
  --color-global-red-750: #6b1e18;
  --color-global-red-800: #581813;
  --color-global-red-850: #44130f;
  --color-global-red-900: #310d0b;
  --color-global-red-950: #1d0806;
  --color-global-red-1000: #000;

  // Global Teal colors
  --color-global-teal-0: #fff;
  --color-global-teal-25: #f5fbfa;
  --color-global-teal-50: #e0f2f1;
  --color-global-teal-100: #cceae7;
  --color-global-teal-150: #b8e2de;
  --color-global-teal-200: #a3d9d4;
  --color-global-teal-250: #8fd1cb;
  --color-global-teal-300: #7ac8c1;
  --color-global-teal-350: #66c0b8;
  --color-global-teal-400: #52b8ae;
  --color-global-teal-450: #3dafa5;
  --color-global-teal-500: #009688;
  --color-global-teal-550: #007267;
  --color-global-teal-600: #00665d;
  --color-global-teal-650: #005a52;
  --color-global-teal-700: #004e47;
  --color-global-teal-750: #00423c;
  --color-global-teal-800: #003631;
  --color-global-teal-850: #002a26;
  --color-global-teal-900: #001e1b;
  --color-global-teal-950: #001210;
  --color-global-teal-1000: #000;

  // Absolute Token colors for the token page elements
  --absolute-token-element-1: #6a79fb;
  --absolute-token-element-2: #6afbfb;
  --absolute-token-element-3: #6d6afb;
  --absolute-token-element-4: #fb6aca;
  --absolute-token-element-5: #6afbf2;
  --absolute-token-element-6: #6aadfb;
  --absolute-token-element-7: #5a9bea;

  // Default
  --default-black: var(--color-brand-neutral-1000);
  --default-white: var(--color-brand-neutral-0);

  // Text
  --text-default-primary: var(--color-brand-neutral-0);
  --text-default-secondary: var(--color-brand-neutral-350);
  --text-default-muted: var(--color-brand-neutral-500);
  --text-default-invert: var(--color-brand-neutral-950);

  // Accent colors
  --text-accent-graphic1: var(--color-brand-graphic1-400);
  --text-accent-grapgic2: var(--color-brand-graphic2-400);
  --text-accent-graphic3: var(--color-brand-graphic3-400);
  --text-accent-graphic4: var(--color-brand-graphic4-400);

  // Surface | Background
  --surface-primary: var(--color-brand-neutral-950);
  --surface-secondary: var(--color-brand-neutral-900);
  --surface-tertiary: var(--color-brand-neutral-850);
  --surface-invert: var(--color-brand-neutral-0);
  --surface-muted: var(--color-brand-neutral-700);

  // Notification colors

  --notification-success-default: var(--color-global-green-500);
  --notification-success-shade: var(--color-global-green-900);
  --notification-alert-default: var(--color-global-amber-500);
  --notification-alert-shade: var(--color-global-amber-900);
  --notification-error-default: var(--color-global-red-500);
  --notification-error-shade: var(--color-global-red-900);
  --notification-system-default: var(--color-brand-main-500);
  --notification-system-shade: var(--color-brand-main-900);
  --notification-info-default: var(--color-global-blue-500);
  --notification-info-shade: var(--color-global-blue-900);

  // Borders
  --border-default: var(--color-brand-neutral-850);
  --border-divider: rgb(106 106 135 / 16%);

  // Brand colors
  --brand-primary-default: var(--color-brand-main-500);
  --brand-primary-hover: var(--color-brand-main-550);
  --brand-primary-focus: var(--color-brand-main-450);
  --brand-primary-shade: var(--color-brand-main-850);

  // Brand secondary colors
  --brand-secondary-default: var(--color-brand-secondary-500);
  --brand-secondary-hover: var(--color-brand-secondary-550);
  --brand-secondary-focus: var(--color-brand-secondary-450);
  --brand-secondary-shade: var(--color-brand-secondary-850);

  --mat-toolbar-container-background-color: var(--default-black);
}
