.mat-expansion-panel:not([class*='mat-elevation-z']) {
  box-shadow: none !important;
}

.mat-accordion .mat-expansion-panel:last-of-type {
  border-radius: 0 !important;
}

.mat-expansion-indicator {
  width: 24px;
}
.mat-expansion-indicator svg {
  margin: 0 !important;
}

.mat-expansion-panel-body {
  padding: 0 !important;
}
